<template>
  <div>
    <a-modal
      width="1150px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :loading="submitting"
      title="编辑套餐"
      @ok="handleSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form
          class="iotplt-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-row>
            <!--基本信息-->
            <a-col :span="12">
              <span class="item-title">基本信息</span>
              <a-form-item label="套餐名称">
                <a-input
                  v-decorator="['name', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入套餐名称' },
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="运营商种类"
                class="iotplt-display-item"
              >
                {{ data.carrier_type }}
              </a-form-item>

              <a-form-item
                label="用户业务模式"
                class="iotplt-display-item"
              >
                {{ data.user_business_type }}
              </a-form-item>

              <a-form-item
                label="套餐周期"
                class="iotplt-display-item"
              >
                {{ data.service_cycle }}
              </a-form-item>

              <a-form-item
                label="套餐容量"
                class="iotplt-display-item"
              >
                {{ data.package_capacity }}
              </a-form-item>

              <a-form-item
                label="语音"
                class="iotplt-display-item"
              >
                {{ data.voice_capacity }}
              </a-form-item>

              <a-form-item label="套餐描述">
                <a-textarea
                  v-decorator="['description', {
                    rules: [
                      { max: 200, message: '最多200个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="单卡可订购周期数(个)"
                class="iotplt-display-item"
                v-show="userBusinessType === 'custom_day'"
              >
                {{ data.limit_subscribe_cycle }}
              </a-form-item>
            </a-col>

            <!--价格设置-->
            <a-col :span="12">
              <span class="item-title">价格设置</span>

              <a-row
                :gutter="24"
                style="padding-left: 2px; margin-top: 10px"
              >
                <a-col
                  :span="favorablePriceSpanSize"
                  v-for="label in favorablePriceLabels"
                  :key="label"
                >
                  {{ label }}
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="(item, index) in agentsProductFavorablePrices"
                :key="item.id"
              >
                <a-col :span="favorablePriceSpanSize">
                  <a-form-item class="iotplt-display-item">
                    {{ item.cycles }}
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize">
                  <a-form-item class="iotplt-display-item">
                    {{ item.distributor_price | formatCurrency }}
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize" v-if="isAllowRetail && data.retail_operator == 'platform'">
                  <a-form-item class="iotplt-display-item">
                    {{ item.retail_price | formatCurrency }}
                  </a-form-item>
                </a-col>

                <a-col :span="favorablePriceSpanSize" v-if="agentType === 1 && isAllowRetail && data.retail_operator == 'user'">
                  <a-input-group compact>
                    <a-form-item>
                      <a-input-number
                        :min="0.01"
                        :max="10000000"
                        :step="0.01"
                        :precision="2"
                        style="width: 120px"
                        v-decorator="[`agents_product_favorable_prices[${index}].retail_price`, {
                          initialValue: item.retail_price,
                          rules: [
                            { required: isAllowRetail, message: '请输入零售价' },
                            { validator: (rule, value, callback) => checkRetailPrice(rule, value, callback, index) }
                          ]
                        }]"
                      />
                    </a-form-item>
                  </a-input-group>
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
                <a-col :span="12">
                  超额计费单位
                </a-col>
                <a-col :span="12">
                  价格(元)
                </a-col>
              </a-row>

              <a-row style="margin-top: 10px" :gutter="24" v-show="isShowExcessRateItem">
                <a-col :span="12">
                  <a-form-item class="iotplt-display-item">
                    {{ data.excess_rate_quantity }}
                  </a-form-item>
                </a-col>

                <a-col :span="12">
                  <a-form-item class="iotplt-display-item">
                    {{ data.excess_distributor_price | formatFloat }}
                  </a-form-item>
                </a-col>
              </a-row>

              <span class="item-title">卡片设置</span>
              <a-row style="margin-top: 10px; margin-bottom: 10px;" :gutter="24">
                <a-col :span="12">
                  卡片材质
                </a-col>
                <a-col :span="12">
                  卡板价格(元)
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                v-for="cardType in data.agents_products_card_types"
                :key="cardType.id"
              >
                <a-col :span="12">
                  <a-form-item class="iotplt-display-item">
                    {{ cardType.card_type_name }}
                  </a-form-item>
                </a-col>

                <a-col :span="12">
                  <a-form-item class="iotplt-display-item">
                    {{ cardType.price | formatCurrency }}
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentsProductForm, updateAgentsProduct } from '@/api/agents_product'

export default {
  name: 'EditAgentsProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isShowExcessRateItem: true, // 是否显示设置超额参数一项
      userBusinessType: 'custom_month',
      data: {},
      agentsProductFavorablePrices: {
        type: Object
      },
      spinning: true,
      isAllowRetail: false, // 是否允许零售
      favorablePriceLabels: ['周期数', '套餐价格(元)'], // 价格设置label
      favorablePriceSpanSize: 12, // 价格设置span大小
      submitting: false,
      form: this.$form.createForm(this, { name: 'agents_product' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    agentType() {
      return parseInt(this.$store.getters.userAgentType)
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      this.spinning = true
      findAgentsProductForm(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.userBusinessType = this.data.user_business_type_slug
          this.agentsProductFavorablePrices = res.data.agents_product_favorable_prices
          this.isAllowRetail = this.data.is_allow_retail

          if (this.isAllowRetail) {
            this.favorablePriceLabels.push('零售价(元)')
            this.favorablePriceSpanSize = 8
          }

          // 处理零售价格
          this.agentsProductFavorablePrices.forEach((value) => {
            if (value.retail_price === -1) {
              value.retail_price = undefined
            }
          })

          if (this.userBusinessType === 'custom_day' || this.isAllowRetail) {
            this.isShowExcessRateItem = false
          }

          this.form.setFieldsValue({
            name: this.data.name,
            description: this.data.description
          })
        }

        this.spinning = false
      })
    },

    checkRetailPrice(rule, value, callback, k) {
      if (!value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback()
        return
      }

      const distributorPrice = this.agentsProductFavorablePrices[k].distributor_price
      if (value < distributorPrice) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('不能低于标准价!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true

          const data = {
            ...values
          }

          if (values.agents_product_favorable_prices) {
            const agentsProductFavorablePrices = []
            values.agents_product_favorable_prices.forEach((favorablePrice, index) => {
              agentsProductFavorablePrices.push({
                cycles: this.agentsProductFavorablePrices[index].cycles,
                retail_price: favorablePrice.retail_price
              })
            })

            data.agents_product_favorable_prices = agentsProductFavorablePrices
          }

          updateAgentsProduct(this.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .item-title {
    color: @primary-color;
  }
</style>
